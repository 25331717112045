import { GET_USER_SUCCESS, LOGIN_SUCCESS, LOGOUT } from './loginActions'

export const login = localStorage => {
	const token = localStorage.getItem('token')

	const initialState = {
		token,
		authenticated: false
	}

	return (state = initialState, action) => {
		switch(action.type) {
			case LOGIN_SUCCESS:
				return {
					...state,
					token: action.token,
					authenticated: true
				}
			case GET_USER_SUCCESS:
				return {
					...state,
					...action.user
				}
			case LOGOUT:
				localStorage.removeItem('token')
				return {
					authenticated: false
				}
			default:
				return state
		}
	}

}