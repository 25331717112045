import { createSelector } from 'reselect'
import { LOADER_TYPE } from './passageExamenReducers'

const getInfosServeurLocal = state => state.infosServeurLocal

export const getUrlAnnexes = createSelector(
	getInfosServeurLocal,
	infos => infos.urlAnnexes
)

export const getUrlServeurLocal = createSelector(
	getInfosServeurLocal,
	infos => infos.urlServeurLocal
)

export const getSujet = state => state.sujet

export const getQuestions = state => state.questions

export const getQuestionSelectionnee = state => state.questionSelectionnee

export const getContenuQuestion = createSelector(
	getQuestions,
	getQuestionSelectionnee,
	(questions, questionSelectionnee) => questions[questionSelectionnee]
)

export const isPremiereQuestion = createSelector(
	getQuestionSelectionnee,
	questionSelectionnee => questionSelectionnee === 1
)

export const isDerniereQuestion = createSelector(
	getQuestions,
	getQuestionSelectionnee,
	(questions, questionSelectionnee) => questionSelectionnee === Object.keys(questions).length
)

export const getTexteReponse = libelleReponse => createSelector(
	getContenuQuestion,
	question => question[`reponse${libelleReponse}`]
)

export const isReponseSelectionnee = libelleReponse => createSelector(
	getContenuQuestion,
	question => question.reponseSelectionnee === libelleReponse
)

export const showLoader = state => state.showLoader !== LOADER_TYPE.AUCUN
export const showLoaderMarquage = state => state.showLoader === LOADER_TYPE.MARQUAGE
export const showLoaderReponse = libelleReponse => state => state.showLoader === LOADER_TYPE.RECONNEXION || state.showLoader === libelleReponse