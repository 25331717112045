import { replace } from 'connected-react-router'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import connect from 'react-redux/lib/connect/connect'
import { compose } from 'redux'

const TerminerEpreuveBouton = ({ terminerEpreuve, intl, displayQuestion }) => {

	const [open, setOpen] = React.useState(false)
	const buttonRef = React.useRef(null)

	const openPopin = () => {
		setOpen(true)
		setTimeout(() => {
			if (buttonRef.current) {
				buttonRef.current.focus()
			}
		}, 0)
	}

	return (
		<>
			<button className="btn valid active" onClick={ () => {openPopin(); displayQuestion(false);}}>
				<FormattedMessage id="timer.terminer" />
				<span className="fa fa-plane ml-2" />
			</button>
			{open && <>
				<div id="overlay" />
				<div id="modal">
					<div className="container">
						<div className="content">
							<div className="title">
								<h4>{intl.formatMessage({ id: 'terminerEpreuve.confirm' })}</h4>
							</div>
							<div className="buttons">
								<button tabIndex={2} className="btn active non" onClick={() => {setOpen(false); displayQuestion(true);}}>
									<FormattedMessage id="terminerEpreuve.non" />
								</button>
								<button tabIndex={1} ref={buttonRef} className="btn valid active oui" onClick={terminerEpreuve}>
									<FormattedMessage id="terminerEpreuve.oui" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</>}
		</>
	)
}

const actions = {
	terminerEpreuve: () => dispatch => dispatch(replace('/epreuve-terminee'))
}

export default compose(
	injectIntl,
	connect(undefined, actions)
)(TerminerEpreuveBouton)
