import { fetchFactory, stopTestWanConnexion, wanKO } from '../../../utils/fetchFactory'
import 'whatwg-fetch'

const soumettreEpreuveOPM = (numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions) => {
	const listQuestions = Object.keys(questions).map(key => questions[key])
	const reponses = new Array(listQuestions.length)
	listQuestions.forEach((question) => {
		let reponse = undefined
		if (Boolean(question.reponseSelectionnee)) {
			if (question.marquage) {
				reponse = `${question.reponseSelectionnee}X`
			} else {
				reponse = question.reponseSelectionnee
			}
		} else if (question.marquage) {
			reponse = 'X'
		}
		reponses[question.position - 1] = reponse
	})
	stopTestWanConnexion()
	window.open(`${urlServeurLocal}?action=0&inscription=${numeroInscription}&libCourtEpreuve=${libelleCourtEpreuve}&reponses=${window.btoa(reponses.toString())}`, 'Confirmation fin d&#39;épreuve', 'width=1,height=1,left=0,top=700');
	return Promise.resolve()
}

let soumettreEpreuveTimeout = null

export const terminerEpreuve = (numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions) => {
	if (wanKO) {
		return soumettreEpreuveOPM(numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions)
	}
	soumettreEpreuveTimeout = setTimeout(() => soumettreEpreuveOPM(numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions), 90000)
	return fetchFactory('/terminer-epreuve', {
		method: 'POST'
	})
		.then(() => {
			clearTimeout(soumettreEpreuveTimeout)
			if (wanKO) {
				return soumettreEpreuveOPM(numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions)
			}
			return Promise.resolve()
		})

}
