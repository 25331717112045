import React from 'react'
import { connect } from 'react-redux'
import SvgLogoDgac from '../../../components/OceaneLogo'
import * as loginSelectors from '../../login/services/loginSelectors'
import * as passageExamenSelectors from '../services/passageExamenSelectors'
import TerminerEpreuveBouton from './TerminerEpreuveBouton'
import Timer from './Timer'
import { FormattedMessage } from 'react-intl'

const FilAriane = ({ user, sujet, changeDisplay}) => {

	const setDisplayQuestion = (value) =>{
		changeDisplay(value)
	}

	return (
		<nav className="main-nav">
			<div className="nav-left">
				<SvgLogoDgac className="logo" />
				<div className="brand">Oceane</div>
				<div className="breadcrumb-item">
					<div className="name">{user.nomComplet.toUpperCase()}</div>
					<div className="breadcrumb-item-epreuve">
						<FormattedMessage id="filAriane.examen" values={{ nomExamen: sujet.libelleCourtExamenType }} />
						<span>&gt;</span>
						<FormattedMessage id="filAriane.epreuve" values={{ libelleCourt: sujet.libelleCourtEpreuveType, libelleLong: sujet.libelleLongEpreuveType, total: sujet.scoreEpreuve }} />
					</div>
				</div>
			</div>
			<div className="nav-right">
				<Timer />
				<TerminerEpreuveBouton displayQuestion={setDisplayQuestion}/>
			</div>
		</nav>
	)
}

const mapStateToProps = (state) => ({
	user: loginSelectors.getUser(state),
	sujet: passageExamenSelectors.getSujet(state)
})

const actions = {}

export default connect(mapStateToProps, actions)(FilAriane)
