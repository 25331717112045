import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'


const TempsEcoule = ({ intl }) => (
	<div className="main-body container-fluid login temps-ecoule">
		<div className="container">
			<div className="content">
				<div className="title">
					<h4>{intl.formatMessage({ id: 'terminerEpreuve.tempsImpartiEcoule.1' })}</h4>
				</div>
				<FormattedMessage id="terminerEpreuve.tempsImpartiEcoule.2" />
			</div>
		</div>
	</div>
)


export default injectIntl(TempsEcoule)
