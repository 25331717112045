import React from 'react'
import * as passageExamenSelectors from '../services/passageExamenSelectors'
import { selectionnerReponse } from '../services/passageExamenActions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import Loading from 'react-viewer/lib/Loading'
import { renderTexte } from '../../../utils/questionUtils'

const BlocReponse = ({ position, libelleReponse, texteReponse, reponseSelectionnee, selectionnerReponse, showLoader }) => (
	<button className={classNames('answer-button', { selected: reponseSelectionnee })} onClick={() => selectionnerReponse(position, libelleReponse)}>
		{showLoader && <Loading />}
		<div className="answer">
			<div className="answer-title">
				Réponse {libelleReponse}
			</div>
			<div className="answer-text">
				{renderTexte(texteReponse)}
			</div>
		</div>
	</button>
)

const mapStateToProps = (state, ownProps) => ({
	texteReponse: passageExamenSelectors.getTexteReponse(ownProps.libelleReponse)(state),
	reponseSelectionnee: passageExamenSelectors.isReponseSelectionnee(ownProps.libelleReponse)(state),
	showLoader: passageExamenSelectors.showLoaderReponse(ownProps.libelleReponse)(state)
})

const actions = {
	selectionnerReponse
}

export default connect(mapStateToProps, actions)(BlocReponse)
