import React from 'react'
import Login from '../modules/login/Login'
import '../assets/css/style-base.min.css'
import '../assets/css/style-charte.min.css'
import PassageExamen from '../modules/passageExamen/PassageExamen'
import { Route, Switch } from 'react-router-dom'
import TerminerEpreuve from '../modules/terminerepreuve/TerminerEpreuve'
import ForceLogin from '../modules/login/ForceLogin'


const App = () => <Switch>
	<Route exact path="/" component={Login} />
	<Route exact path="/force-login" component={ForceLogin} />
	<Route exact path="/epreuve" component={PassageExamen} />
	<Route exact path="/epreuve-terminee" component={TerminerEpreuve} />
</Switch>


export default App
