import React from 'react'

export const TimerContext = React.createContext({})
let timerInterval

export const TimerProviderWrapper = ({ children }) => {

	const [timer, setTimer] = React.useState(0)

	const resetTimer = React.useCallback(duration => {
		if (timerInterval != null) {
			clearInterval(timerInterval)
			timerInterval = null
		}
		setTimer(duration)
		timerInterval = setInterval(() => setTimer(timer => timer - 1), 1000)
	}, [])

	const timerMinutes = Math.ceil(timer / 60)

	React.useEffect(() => {
		if (timerMinutes <= 0 && timerInterval != null) {
			clearInterval(timerInterval)
			timerInterval = null
		}
	}, [timerMinutes])

	const contextValues = {
		timerMinutes,
		resetTimer
	}

	return (
		<TimerContext.Provider value={contextValues}>
			{children}
		</TimerContext.Provider>
	)
}