import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import SvgLogoDgac from '../../components/OceaneLogo'
import { replace } from 'connected-react-router'
import { forceLogin, getUser } from './services/loginActions'
import { getInfosServeurLocal, getSujet } from '../passageExamen/services/passageExamenActions'
import Loading from 'react-viewer/lib/Loading'

const ForceLogin = ({ location: { state: { message, values } }, intl, forceLogin, redirectToLogin, getUser, redirectToEpreuvePage, getInfosServeurLocal, getSujet }) => {

	React.useEffect(() => {
		document.body.classList.toggle('login-content', true)
		document.body.parentNode.classList.toggle('login-content', true)

		return () => {
			document.body.classList.toggle('login-content', false)
			document.body.parentNode.classList.toggle('login-content', false)
		}
	})

	const [loading, setLoading] = React.useState(false)

	const handleForceLogin = () => {
		setLoading(true)
		forceLogin(values.login, values.password, intl.locale)
			.catch(e => {
				redirectToLogin()
				throw e
			})
			.then(({ token }) => getUser(token))
			.then(() => Promise.all([getInfosServeurLocal(), getSujet()]))
			.then(redirectToEpreuvePage)
	}

	return (
		<>
			<nav className="main-nav">
				<div className="nav-left">
					<div className="logo">
						<SvgLogoDgac className="logo" />
						<div className="brand">Oceane</div>
					</div>
				</div>
			</nav>

			<div className="main-body container-fluid login">
				<div className="container">
					<div className="content">
						<div className="title">
							<h4>{intl.formatMessage({ id: 'loginForm.titre' })}</h4>
						</div>
						<div>{intl.formatMessage({ id: message })}</div>
						<div className="force-login buttons">
							<button
								className="btn force mt-4"
								type="button"
								onClick={handleForceLogin}
							>
								{loading && <Loading />}
								{!loading && intl.formatMessage({ id: 'loginForm.ignorer' })}
							</button>
							<button className="btn valid mt-4" type="button" onClick={redirectToLogin}>{intl.formatMessage({ id: 'loginForm.annuler' })}</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const actions = {
	forceLogin,
	getUser,
	getSujet,
	getInfosServeurLocal,
	redirectToEpreuvePage: () => dispatch => dispatch(replace('/epreuve')),
	redirectToLogin: () => dispatch => dispatch(replace('/')),

}

export default compose(
	injectIntl,
	connect(undefined, actions)
)(ForceLogin)
