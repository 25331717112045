import { flatten } from 'flat'

export default flatten({
	loginForm: {
		titre: 'Identification',
		identifiant: 'Identifiant',
		motDePasse: 'Mot de passe',
		accederEpreuve: 'Accéder à l\'épreuve',
		annuler: 'Annuler (fortement recommandé)',
		ignorer: 'Ignorer et accéder à l\'épreuve',
		consulterConsignes: 'Consulter les consignes'
	},
	filAriane: {
		examen: 'Examen {nomExamen}',
		epreuve: 'Epreuve {libelleCourt} / {libelleLong} ({total} {total, plural, one {point} other {points}})'
	},
	timer: {
		tempsRestant: 'Il vous reste',
		terminer: 'Terminer l\'épreuve'
	},
	nbPoints: '+{points} {points, plural, one {point} other {points}}',
	legend: {
		nonrepondue: 'Non répondue',
		marquee: 'Marquée',
		repondue: 'Répondue'
	},
	marquage: {
		retirer: 'Retirer le marquage',
		marquer: 'Marquer cette question'
	},
	errors: {
		login: {
			invalide: 'L\'identification saisie est incorrecte. Merci de saisir à nouveau l\'identifiant et le mot de passe qui vous ont été remis à votre entrée en salle d\'examen. N\'hésitez pas à solliciter le superviseur de la salle en cas de problème',
			reconnexion: 'Vous n\'êtes pas autorisés à vous connecter une seconde fois. Merci de vous adresser au superviseur',
			reponseCentral: 'L\'épreuve est terminée et le fichier réponses est enregistré sur le serveur central.',
			reponseSecours: 'L\'épreuve est terminée et le fichier réponses est enregistré sur le serveur de secours.'
		},
		exception: {
			hibernate: 'Échec : problème survenu lors de la sauvegarde de la réponse.',
			baseDonnees: 'Échec : la base de données est hors service, merci de prévenir le superviseur.',
			generationSujet: 'L\'état actuel de l\'application ne permet pas de générer le sujet d\'examen.'
		},
		pex: {
			connexionImpossible: 'Impossible de se connecter',
			login: {
				invalide: 'L\'identification saisie est incorrecte. Merci de saisir à nouveau l\'identifiant et le mot de passe qui vous ont été remis à votre entrée en salle d\'examen. N\'hésitez pas à solliciter le superviseur de la salle en cas de problème'
			}
		},
		temps: {
			depasse: 'Vous avez dépassé le temps prévu pour terminer votre épreuve'
		}
	},
	terminerEpreuve: {
		confirm: 'Confirmez-vous la fin de l\'épreuve ?',
		oui: 'Oui',
		non: 'Non',
		message: 'Votre épreuve est désormais terminée, merci de vous diriger vers la sortie et de vous présenter au superviseur',
		quitter: 'Quitter',
		tempsImpartiEcoule: {
			1: 'Fin du temps imparti',
			2: 'Merci de cliquer sur "Terminer l\'épreuve"'
		}
	},
	consignes: {
		title: 'Consignes pour le passage d’une épreuve sur écran',
		closeWindow: 'Fermer la fenêtre',
		summary: {
			1: 'Accès en salle',
			2: 'Matériel et documents autorisés',
			3: 'Passage de l’épreuve',
			'3_1': 'Connexion',
			'3_2': 'Durée et fin de l’épreuve',
			'3_3': 'Questions',
			'3_4': 'Navigation dans le sujet',
			'3_5': 'Fonctionnalités',
			4: 'Sortie'
		},
		parts: {
			1: {
				row1: 'Veuillez vous présenter <b>30 minutes</b> avant le début de l’épreuve au guichet d’entrée de la salle d’examen.',
				row2: 'Après le contrôle de votre identité, vérifier que :',
				row3: 'Votre <b>fiche d’accès </b>pour votre épreuve est correcte ',
				row4: 'Vous disposez du <b>recueil d’annexes</b> si nécessaire pour l’épreuve ',
				row5: 'Rejoignez et installez vous au poste qui vous a été attribué en silence pour ne pas déranger les candidats en cours d’épreuve.',
				row6: '<b><i>Rque</i></b><i> : une fiche d’accès est émise pour chaque épreuve.</i>'
			},
			2: {
				row1: 'En salle d’examen, seuls sont autorisés :',
				row2: 'La fiche d’identification ',
				row3: 'Le recueil d’annexes si besoin pour l’épreuve',
				row4: 'Le brouillon fourni par le superviseur ',
				row5: 'Un plateau calculateur de navigation <b><u>SANS</u></b> la notice d’utilisation ;',
				row6: 'Un compas à pointe sèche ;',
				row7: 'Une règle de navigation ;',
				row8: 'Crayon(s) à papier, stylo(s) et gomme.',
				row9: 'TOUT AUTRE DOCUMENT OU MATERIEL EST RIGOUREUSEMENT INTERDIT, ET ENTRAINERA SYSTEMATIQUEMENT UNE PROCEDURE DE CONSTAT DE TENTATIVE DE FRAUDE',
				row10: 'Placez vos effets personnels aux lieux indiqués.'
			},
			3: {
				row1: 'Veuillez <b>maintenir un silence strict dans la salle d’examens</b> par respect envers autres candidats.',
				1: {
					row1: 'Pour débuter,&nbsp;veuillez <b>saisir</b> les <b>identifiant</b> et <b>mot de passe</b> indiqués sur votre <b>fiche</b> <b>d’accès</b> pour votre épreuve. ',
					row2: 'A la connexion, le système génère automatiquement votre sujet puis débute le décompte du temps.'
				},
				2: {
					row1: 'Le <b>temps restant</b> pour composer est <b>précisé en haut et à droite de l’écran.</b>',
					row2: 'Vous pouvez terminer votre épreuve à tout moment en cliquant sur le bouton « <b>terminer l’épreuve </b>+. ',
					row3: ' Si le temps imparti pour l’épreuve est écoulé, le système ne vous autorisera pas à poursuivre votre épreuve et vous invitera à la terminer.'
				},
				3: {
					row1: 'Les <b>questions</b> de ce sujet sont <b>indépendantes</b>. Chaque question possède <b>un score particulier de points</b>, indiqué à côté du numéro de la question. ',
					row2: '<b>Une seule </b>des quatre propositions est <b>correcte</b>. Il n’y a <b>pas de point négatif.</b>',
					row3: 'Pour <b>saisir ou modifier votre réponse</b> à la question, cliquez sur la proposition de votre choix. La réponse que vous avez choisie passe en surbrillance.',
					row4: 'Si une question requière l’<b>utilisation </b>d’une ou plusieurs <b>annexes</b>, cliquer sur le lien correspondant pour ouvrir le document dans une nouvelle fenêtre. ',
					row5: 'Fermer la fenêtre après consultation pour ne pas encombrer inutilement votre écran.',
					row6: '<b>Certaines annexes </b>notamment les abaques, dont l’<b>utilisation</b> nécessite un support <b>papier</b>, sont également <b>disponibles</b> dans le <b>recueil </b>d’annexes fourni avant l’entrée en salle.'
				},
				4: {
					row1: 'Vous pouvez vous déplacer dans le sujet en utilisant :',
					row2: 'les flèches précédente (<b>&lt;&lt;</b>) et suivante (<b>&gt;&gt;</b>) pour passer d’une question à la précédente ou à la suivante.',
					row3: 'le <b>tableau des questions </b>qui<b> </b>permet d’accéder directement à une question du sujet<i> (clic sur le numéro de la question désirée)</i>.',
					row4: 'Le<b> tableau des questions</b> vous <b>renseigne</b> également sur l’<b>avancée de votre travail </b><i>(questions répondues ou non)</i> et vous indique le ou les questions que vous avez marquées, vous permettant d’y revenir rapidement à tout moment. '
				},
				5: {
					row1: '<b>Calculatrice</b>&nbsp;: Si l’épreuve le permet, une calculatrice test disponible par clic sur le pictogramme correspondant. ',
					row2: '<b>Marquage de question : </b>Pour vous aider ultérieurement à revenir rapidement sur une question, vous pouvez marquée une question (clic sur le pictogramme <b>marquer la question</b>. Vous pouvez retirer le marquage d’une question selon le même principe.',
					row3: '<b>Rappel des consignes</b> : A tout moment pendant votre épreuve vous pouvez afficher les pr'
				}
			},
			4: {
				row1: 'Veuillez <b>ranger</b> votre position d’examen pour le ou la candidat(e) suivant(e).',
				row2: 'Récupérer tous les documents qui vous ont été fournis en entrée de salle. ',
				row3: 'Rejoindre en <b>silence</b> le guichet de sortie.',
				row4: 'Pour obtenir votre résultat, veuillez <b>remettre</b> au superviseur :',
				row5: 'Votre <b>fiche d’accès</b>',
				row6: 'Les <b>brouillons</b>',
				row7: 'Le <b>recueil d’annexes</b> le cas échéant ',
				row8: 'Et <b>tout autre document ou matériel </b>qui vous a été <b>fourni</b>.'
			}
		}
	}
})
