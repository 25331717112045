import React from 'react'
import { IntlProvider } from 'react-intl'
import messagesFr from '../config/messagesFr'
import messagesEn from '../config/messagesEn'

const messages = {
	fr: messagesFr,
	en: messagesEn
}

const formats = {
	date: {
		datetime: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}
	}
}

export const IntlContext = React.createContext({})

export const IntlProviderWrapper = ({ children }) => {

	const [intl, setIntl] = React.useState({
		locale: 'fr',
		messages: messages.fr
	})

	const changeLanguage = locale => {
		setIntl({
			locale,
			messages: messages[locale]
		})
	}

	const contextValues = {
		changeLanguage
	}

	return <IntlContext.Provider value={contextValues}>
		<IntlProvider
			locale={intl.locale}
			key={intl.locale}
			messages={intl.messages}
			formats={formats}
			defaultLocale="fr"
			defaultMessages={messages.fr}
			defaultsFormats={formats}
		>
			{children}
		</IntlProvider>
	</IntlContext.Provider>
}