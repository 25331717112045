import classNames from 'classnames'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectionnerQuestion } from '../services/passageExamenActions'
import * as passageExamenSelectors from '../services/passageExamenSelectors'
import Calculette from './Calculette'
import ConsignesCandidat from '../../consignes/ConsignesCandidat'

const ListeQuestions = ({ sujet, intl, questions, selectionnerQuestion }) => {

	const [openCalc, setOpenCalc] = React.useState(false)
	const [displayConsignes, setDisplayConsignes] = React.useState(false)

	return (
		<footer className="main-footer">
			<div className="container-fluid">
				<div className="container">
					<div className="main-aide">
						<span
							className="fa fa-question-circle ft-s-32 c-primary-1"
							onClick={() => setDisplayConsignes(true)}
						/>
					</div>
					<div className="main-question">
						<div className="legend">
							<ul>
								<li className="no-answer">{intl.formatMessage({ id: 'legend.nonrepondue' })}</li>
								<li className="marked">{intl.formatMessage({ id: 'legend.marquee' })}</li>
								<li className="answered">{intl.formatMessage({ id: 'legend.repondue' })}</li>
							</ul>
						</div>
						<div className="list-fav">
							{Object.keys(questions).map((key, index) => (
								<button
									className={classNames({
										repondue: Boolean(questions[key].reponseSelectionnee) && !questions[key].marquage,
										marquee: questions[key].marquage
									})}
									onClick={() => selectionnerQuestion(questions[key].position)} key={index}
								>
									{`0${questions[key].position}`.slice(-2)}
								</button>
							))}
						</div>
					</div>
					{sujet.calculetteAutorisee && <div className="main-calc">
						<span className="fa fa-calculator ft-s-32 c-primary-1" onClick={() => setOpenCalc(true)} />
					</div>}
				</div>
			</div>
			{openCalc && <Calculette close={() => setOpenCalc(false)} />}
			<ConsignesCandidat display={displayConsignes} hide={() => setDisplayConsignes(false)}/>
		</footer>
	)
}

const mapStateToProps = (state) => ({
	sujet: passageExamenSelectors.getSujet(state),
	questions: passageExamenSelectors.getQuestions(state)
})

const actions = {
	selectionnerQuestion
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions)
)(ListeQuestions)
