import * as api from './terminerEpreuveApi'

export const TERMINER_EPREUVE_REQUEST = 'TERMINER_EPREUVE_REQUEST'
export const TERMINER_EPREUVE_SUCCESS = 'TERMINER_EPREUVE_SUCCESS'
export const TERMINER_EPREUVE_ERROR = 'TERMINER_EPREUVE_ERROR'
export const terminerEpreuve = (numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions) => dispatch => {
	dispatch({ type: TERMINER_EPREUVE_REQUEST })
	return api.terminerEpreuve(numeroInscription, libelleCourtEpreuve, urlServeurLocal, questions)
		.then(() => dispatch({ type: TERMINER_EPREUVE_SUCCESS}))
		.catch(e => {
			dispatch({ type: TERMINER_EPREUVE_ERROR, e })
			throw e
		})
}

