import {
	GET_INFOS_SERVEUR_LOCAL_SUCCESS,
	GET_SUJET_SUCCESS,
	MARQUER_QUESTION_REQUEST,
	MARQUER_QUESTION_SUCCESS,
	MISE_A_JOUR_EPREUVE_REQUEST,
	MISE_A_JOUR_EPREUVE_SUCCESS,
	QUESTION_PRECEDENTE,
	QUESTION_SUIVANTE,
	SELECTIONNER_QUESTION,
	SELECTIONNER_REPONSE_REQUEST,
	SELECTIONNER_REPONSE_SUCCESS
} from './passageExamenActions'
import { LOGOUT } from '../../login/services/loginActions'

const infosServeurLocal = (state = {}, action) => {
	switch (action.type) {
		case GET_INFOS_SERVEUR_LOCAL_SUCCESS:
			return action.infos
		case LOGOUT:
			return {}
		default:
			return state
	}
}

const initialState = {}
const sujet = (state = initialState, action) => {
	switch (action.type) {
		case GET_SUJET_SUCCESS:
			return action.sujet
		case LOGOUT:
			return initialState
		default:
			return state
	}
}

const questionsInit = {}
const questions = (state = questionsInit, action) => {
	switch (action.type) {
		case GET_SUJET_SUCCESS:
			return action.sujet.questions.reduce((acc, question) => {
				return {
					...acc,
					[question.position]: question
				}
			}, {})
		case MARQUER_QUESTION_SUCCESS:
			return {
				...state,
				[action.positionQuestion]: {
					...state[action.positionQuestion],
					marquage: action.marquage
				}
			}
		case SELECTIONNER_REPONSE_SUCCESS:
			return {
				...state,
				[action.positionQuestion]: {
					...state[action.positionQuestion],
					reponseSelectionnee: action.reponse
				}
			}
		case LOGOUT:
			return questionsInit
		default:
			return state
	}
}

const questionInitiale = 1
const questionSelectionnee = (state = questionInitiale, action) => {
	switch (action.type) {
		case GET_SUJET_SUCCESS:
		case LOGOUT:
			return 1
		case SELECTIONNER_QUESTION:
			return action.position
		case QUESTION_PRECEDENTE:
			return state - 1
		case QUESTION_SUIVANTE:
			return state + 1
		default:
			return state
	}
}


export const LOADER_TYPE = {
	AUCUN: 'AUCUN',
	MARQUAGE: 'MARQUAGE',
	RECONNEXION: 'RECONNEXION'
}

const showLoader = (state = LOADER_TYPE.AUCUN, action) => {
	switch (action.type) {
		case MARQUER_QUESTION_REQUEST:
			return LOADER_TYPE.MARQUAGE
		case SELECTIONNER_REPONSE_REQUEST:
			return action.libelleReponse
		case MISE_A_JOUR_EPREUVE_REQUEST:
			return LOADER_TYPE.RECONNEXION
		case MARQUER_QUESTION_SUCCESS:
		case SELECTIONNER_REPONSE_SUCCESS:
		case MISE_A_JOUR_EPREUVE_SUCCESS:
			return LOADER_TYPE.AUCUN
		default:
			return state
	}
}

export const passageExamen = {
	infosServeurLocal,
	sujet,
	questions,
	questionSelectionnee,
	showLoader
}