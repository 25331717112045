/**
 * Affectue l'authentification de l'utilisateur
 */
export const login = (values) => fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=utf-8'
		},
		body: JSON.stringify(values)
	})
		.then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				return new Promise((resolve, reject) => response.json()
					.then(json => reject({
						message: json.message || json.id,
						response,
						bodyError: json
					}))
					.catch(json => reject({
						message: 'errors.pex.connexionImpossible',
						response,
						bodyError: json
					}))
				)
			}
		})
	.then(result => {
		localStorage.setItem('token', result.token)
		return result.token
	})

export const getUser = (token) => fetch(`${process.env.REACT_APP_SERVER_URL}/auth/user`, {
	method: 'GET',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
		'Authorization-Oceane': token
	}
})
	.then(response => {
		if (response.ok) {
			return response.json()
		}
		return
	})

export const removeUser = () => localStorage.removeItem('token')
