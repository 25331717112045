import * as api from './loginApi'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const login = (username, password, locale) => dispatch => {
	dispatch({ type: LOGIN_REQUEST, })
	return api.login({ username, password, locale, force: false })
		.then(token => dispatch({
			type: LOGIN_SUCCESS,
			token
		}))
		.catch(e => {
			dispatch({ type: LOGIN_ERROR })
			throw e
		})
}

export const FORCE_LOGIN_REQUEST = 'FORCE_LOGIN_REQUEST'
export const FORCE_LOGIN_SUCCESS = 'FORCE_LOGIN_SUCCESS'
export const FORCE_LOGIN_ERROR = 'FORCE_LOGIN_ERROR'
export const forceLogin = (username, password, locale) => dispatch => {
	dispatch({ type: FORCE_LOGIN_REQUEST, })
	return api.login({ username, password, locale, force: true })
		.then(token => dispatch({
			type: FORCE_LOGIN_SUCCESS,
			token
		}))
		.catch(e => {
			dispatch({ type: FORCE_LOGIN_ERROR })
			throw e
		})
}

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const getUser = token => dispatch => {
	dispatch({ type: GET_USER_REQUEST })
	return api.getUser(token)
		.then(user => dispatch({
			type: GET_USER_SUCCESS,
			user
		}))
}

export const LOGOUT = 'LOGOUT'
export const logout = () => dispatch => {
	api.removeUser()
	return Promise.resolve(dispatch({ type: LOGOUT }))
}