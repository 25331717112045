import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as passageExamenSelectors from '../services/passageExamenSelectors'
import classNames from 'classnames'
import { replace } from 'connected-react-router'
import { TimerContext } from '../../../utils/TimerContext'
import { FormattedMessage } from 'react-intl'

const Timer = ({ sujet }) => {

	const { timerMinutes, resetTimer } = React.useContext(TimerContext)

	const resetCallback = React.useCallback(() => {
		resetTimer(sujet.tempsRestant)
	}, [resetTimer, sujet.tempsRestant])

	React.useEffect(() => {
		resetCallback()
	}, [resetCallback])

	const colorClass = {
		'c-primary-1': timerMinutes > 15,
		'c-primary-2': timerMinutes > 5 && timerMinutes <= 15,
		'c-alert': timerMinutes <= 5
	}

	return (
		<div className="timer">
			<span className="fa icon-clock mr-2" />
			<div className="timer-text">
				<FormattedMessage id="timer.tempsRestant">
					{txt => <span className="mr-1">{txt}</span>}
				</FormattedMessage>
				<span className={classNames('ft-bold', 'ft-s-14', colorClass)}>{timerMinutes} {timerMinutes > 1 ? 'minutes' : 'minute'}</span>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	sujet: passageExamenSelectors.getSujet(state),
	loading: passageExamenSelectors.showLoader(state)
})

const actions = {
	terminerEpreuve: () => dispatch => dispatch(replace('/epreuve-terminee'))
}

export default compose(
	connect(mapStateToProps, actions)
)(Timer)
