import { replace } from 'connected-react-router'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Loading from 'react-viewer/lib/Loading'
import { compose } from 'redux'
import SvgLogoDgac from '../../components/OceaneLogo'
import { logout } from '../login/services/loginActions'
import * as loginSelectors from '../login/services/loginSelectors'
import * as passageExamenSelectors from '../passageExamen/services/passageExamenSelectors'
import { terminerEpreuve } from './service/terminerEpreuveActions'

const TerminerEpreuve = ({ intl, user, sujet, urlServeurLocal, questions, terminerEpreuve, logout, goToLogin }) => {

	React.useEffect(() => {
		document.body.classList.toggle('login-content', true)
		document.body.parentNode.classList.toggle('login-content', true)

		return () => {
			document.body.classList.toggle('login-content', false)
			document.body.parentNode.classList.toggle('login-content', false)
		}
	})

	const [loader, setLoader] = React.useState(true)

	const logoutRef = React.useRef(-1)

	const handleLogout = React.useCallback(() => {
		clearTimeout(logoutRef.current)
		logout().then(goToLogin)
	}, [goToLogin, logout])

	const fetch = React.useCallback(() => {
		if (user.numeroInscription) {
			terminerEpreuve(user.numeroInscription, sujet.libelleCourtEpreuveType, urlServeurLocal, questions)
				.then(() => {
					setLoader(false)
					logoutRef.current = setTimeout(handleLogout, 60000)
				})
		}
	}, [terminerEpreuve, user.numeroInscription, sujet.libelleCourtEpreuveType, urlServeurLocal, questions, handleLogout])

	React.useEffect(() => {
		fetch()
	}, [fetch])

	return <>
		<nav className="main-nav">
			<div className="nav-left">
				<div className="logo">
					<SvgLogoDgac className="logo" />
					<div className="brand">Oceane</div>
				</div>
			</div>
		</nav>

		<div className="main-body container-fluid login">
			<div className="container">
				<div className="content end">

					{loader && <>
						<span>Envoi en cours...</span>
						<button
							className="btn valid mt-4"
							type="submit"
						>
							<Loading />
						</button>
					</>}
					{!loader && <>
						<FormattedMessage id="terminerEpreuve.message" />
						<button
							className="btn valid mt-4"
							onClick={handleLogout}
						>
							{intl.formatMessage({ id: 'terminerEpreuve.quitter' })}
						</button>
					</>}
				</div>
			</div>
		</div>
	</>
}

const mapStateToProps = state => ({
	user: loginSelectors.getUser(state),
	sujet: passageExamenSelectors.getSujet(state),
	urlServeurLocal: passageExamenSelectors.getUrlServeurLocal(state),
	questions: passageExamenSelectors.getQuestions(state)
})

const actions = {
	terminerEpreuve,
	logout,
	goToLogin: () => dispatch => dispatch(replace('/'))
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions)
)(TerminerEpreuve)
