import { replace } from 'connected-react-router'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Loading from 'react-viewer/lib/Loading'
import { compose } from 'redux'
import SvgLogoDgac from '../../components/OceaneLogo'
import { IntlContext } from '../../utils/IntlContext'
import { getInfosServeurLocal, getSujet } from '../passageExamen/services/passageExamenActions'
import { getUser, login } from './services/loginActions'
import ConsignesCandidat from '../consignes/ConsignesCandidat';

const Login = ({ login, getUser, intl, redirectToEpreuvePage, forceLogin, getInfosServeurLocal, getSujet }) => {

	React.useEffect(() => {
		document.body.classList.toggle('login-content', true)
		document.body.parentNode.classList.toggle('login-content', true)

		return () => {
			document.body.classList.toggle('login-content', false)
			document.body.parentNode.classList.toggle('login-content', false)
		}
	})

	const [loading, setLoading] = React.useState(false)

	const [formValues, setFormValues] = React.useState({
		login: '',
		password: ''
	})

	const [error, setError] = React.useState(null)
	const [displayConsignes, setDisplayConsignes] = React.useState(false)

	const handleChange = event => {
		const { value, name } = event.target
		setFormValues({
			...formValues,
			[name]: value
		})
	}

	const loginSubmit = (event) => {
		setLoading(true)
		event.preventDefault()
		setError(null)
		login(formValues.login, formValues.password, intl.locale)
			.catch(e => {
				if (e.message.includes('reponse')) {
					forceLogin(e.message, formValues)
				} else {
					setError(e.message)
				}
				setLoading(false)
				throw e
			})
			.then(({ token }) => getUser(token))
			.then(() => Promise.all([getInfosServeurLocal(), getSujet()]))
			.then(redirectToEpreuvePage)
			.catch(e => {
				if (e && e.id) {
					setError(e.id)
				}
				setLoading(false)
			})

	}

	return <IntlContext.Consumer>
		{({ changeLanguage }) => <>
			<nav className="main-nav">
				<div className="nav-left">
					<div className="logo">
						<SvgLogoDgac className="logo" />
						<div className="brand">Oceane</div>
					</div>
				</div>
			</nav>

			<div className="main-body container-fluid login">
				<div className="container">
					<div className="content">
						<div className="title">
							<h4>{intl.formatMessage({ id: 'loginForm.titre' })}</h4>
							<div className="drapeaux">
								<img src={`${process.env.PUBLIC_URL}/img/flag_fr.png`} width="33" height="auto" alt="Français"
								     onClick={() => changeLanguage('fr')} />
								<img src={`${process.env.PUBLIC_URL}/img/flag_en.png`} width="33" height="auto" alt="English"
								     onClick={() => changeLanguage('en')} />
							</div>
						</div>
						{error && <div className="error">{intl.formatMessage({ id: error })}</div>}
						<form onSubmit={loginSubmit} autoComplete="off">
							<div className="field">
								<div className="label">
									<span className="fa fa-user ft-s-20 c-primary-1" />
									<label>{intl.formatMessage({ id: 'loginForm.identifiant' })}</label>
								</div>
								<input name="login" value={formValues.login} onChange={handleChange} />
							</div>
							<div className="field">
								<div className="label">
									<span className="fa fa-key ft-s-20 c-primary-1" />
									<label>{intl.formatMessage({ id: 'loginForm.motDePasse' })}</label>
								</div>
								<input name="password" type="password" value={formValues.password} onChange={handleChange} />
							</div>
							<button
								className="btn valid mt-4"
								type="submit"
							>
								{loading && <Loading />}
								{!loading && intl.formatMessage({ id: 'loginForm.accederEpreuve' })}
							</button>
						</form>
						<span
							className="consigne"
							onClick={() => setDisplayConsignes(true)}>
							<span className="mr-2 fa fa-chevron-right c-primary-1" />
							{intl.formatMessage({ id: 'loginForm.consulterConsignes' })}
						</span>
					</div>
				</div>
			</div>
			<ConsignesCandidat display={displayConsignes} hide={() => setDisplayConsignes(false)}/>
		</>}
	</IntlContext.Consumer>
}


const actions = {
	login,
	getUser,
	getInfosServeurLocal,
	getSujet,
	redirectToEpreuvePage: () => dispatch => dispatch(replace('/epreuve')),
	forceLogin: (message, values) => dispatch => dispatch(replace({
		pathname: '/force-login',
		state: { message, values }
	}))
}

export default compose(injectIntl, connect(undefined, actions))(Login)
