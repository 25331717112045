import React from 'react'
import Draggable from 'react-draggable'
import './consignesCandidat.css'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'

const ConsignesCandidat = ({ display = false, hide, intl }) => {
	if (!display) {
		return <></>
	}

	return <Draggable cancel="input" >
		<div className="WordSection1" id="consignes">

			<p className="titre14">
				<span
					style={{
						fontVariant: 'normal !important',
						textTransform: 'uppercase',
						fontSize: '20pt'
					}}
				>
					<FormattedMessage id="consignes.title" />
				</span>
			</p>

			<p className="titre14">
				<span style={{
					fontSize: '9.0pt',
					fontVariant: 'normal',
					fontWeight: 'normal'
				}}>
					&nbsp;
				</span></p>

			<center><input type="button" value={intl.formatMessage({ id: 'consignes.closeWindow'})} onClick={hide} /></center>

			<p className="titre14">
				<span style={{
					fontSize: '9.0pt',
					fontVariant: 'normal',
					fontWeight: 'normal'
				}}>
					&nbsp;
				</span>
			</p>

			<p className="MsoToc1"><span
				className="MsoHyperlink"><span style={{ color: 'blue' }}><a href="#_Toc140555500">1.<span
				style={{
					fontSize: '12.0pt',
					color: 'windowtext',
					textTransform: 'none',
					fontWeight: 'normal',
					textDecoration: 'none'
				}}
			>      </span><FormattedMessage id="consignes.summary.1" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
				style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>1</span></a></span></span></p>

			<p className="MsoToc1"><span className="MsoHyperlink"><span style={{ color: 'blue' }}><a
				href="#_Toc140555501">2.<span
				style={{
					fontSize: '12.0pt',
					color: 'windowtext',
					textTransform: 'none',
					fontWeight: 'normal',
					textDecoration: 'none'
				}}>      </span><FormattedMessage id="consignes.summary.2" /><span style={{ color: 'windowtext' , display: 'none', textDecoration: 'none' }}>. </span><span
					style={{ color: 'windowtext' , display: 'none', textDecoration: 'none' }}>1</span></a></span></span></p>

			<p className="MsoToc1"><span className="MsoHyperlink"><span style={{ color: 'blue' }}><a
				href="#_Toc140555502">3.<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>  
				    </span><FormattedMessage id="consignes.summary.3" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
					style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>2</span></a></span></span></p>

			<p className="MsoToc2"><span className="MsoHyperlink"><span style={{ fontVariant: 'normal !important', color: 'blue', textTranform: 'uppercase' }}><a href="#_Toc140555503">3.1<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>
       </span><FormattedMessage id="consignes.summary.3_1" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
				style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>2</span></a></span></span></p>

			<p className="MsoToc2"><span className="MsoHyperlink"><span style={{ fontVariant: 'normal !important', color: 'blue', textTranform: 'uppercase' }}><a href="#_Toc140555504">3.2<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>        </span><FormattedMessage id="consignes.summary.3_2" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
				style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>2</span></a></span></span></p>

			<p className="MsoToc2"><span className="MsoHyperlink"><span style={{ fontVariant: 'normal !important', color: 'blue', textTranform: 'uppercase' }}><a href="#_Toc140555505">3.3<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>
			        </span><FormattedMessage id="consignes.summary.3_3" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>. </span><span
				style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>2</span></a></span></span></p>

			<p className="MsoToc2"><span className="MsoHyperlink"><span style={{ fontVariant: 'normal !important', color: 'blue', textTranform: 'uppercase' }}><a href="#_Toc140555506">3.4<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>
			        </span><FormattedMessage id="consignes.summary.3_4" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
				style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>3</span></a></span></span></p>

			<p className="MsoToc2"><span className="MsoHyperlink"><span style={{ fontVariant: 'normal !important', color: 'blue', textTranform: 'uppercase' }}><a href="#_Toc140555507">3.5<span
				style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>
			        </span><FormattedMessage id="consignes.summary.3_5" /><span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>. </span>
				<span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>3</span></a></span></span></p>

			<p className="MsoToc1"><span className="MsoHyperlink"><span style={{ color: 'blue' }}><a
				href="#_Toc140555508">4.<span style={{ fontSize: '12.0pt', color: 'windowtext', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none'}}>
				      </span>Sortie
de la salle<span style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>.. </span><span
					style={{ color: 'windowtext', display: 'none', textDecoration: 'none' }}>3</span></a></span></span></p>

			<p className="titre14"><span style={{ fontSize: '9.0pt', fontVariant: 'normal !important', fontWeight: 'normal'}}>&nbsp;</span></p>

			<h1 className="MsoNormal" style={{ marginLeft: '21.6pt'}}><a name="_Toc140555500" href="#_Toc140555500"> </a><a
				name="_Toc140554772" href="#_Toc140554772" className="withoutLink">1.<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.1" /></a></h1>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.1.row1" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.1.row2" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.1.row3" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.1.row4" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.1.row5" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.1.row6" /></p>

			<h1 className="MsoNormal" style={{ marginLeft: '21.6pt'}}><a name="_Toc140555501" href="#_Toc140555501"> </a><a
				name="_Toc140554773" href="#_Toc140554773" className="withoutLink">2.<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.2" /></a></h1>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.2.row1" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row2" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row3" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row4" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.2.row5" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row6" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row7" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedMessage id="consignes.parts.2.row8" /></p>

			<p className="MsoNormal" align="left" style={{ marginRight: '-7.15pt', textAlign: 'left', background: 'white' }}>
				<b><span style={{ fontSize: '9.0pt' }}><FormattedMessage id="consignes.parts.2.row9" /></span></b></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.2.row10" /></p>

			<h1 className="MsoNormal" style={{ marginLeft: '21.6pt'}}><a name="_Toc140555502" href="#_Toc140555502"> </a><a
				name="_Toc140554774" href="#_Toc140554774" className="withoutLink">3.<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3" /></a></h1>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.row1" /></p>

			<h2 className="MsoNormal"><a name="_Toc140555503" href="#_Toc140555503"> </a><a name="_Toc140554775" href="#_Toc140554775" className="withoutLink">3.1<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3_1" /></a></h2>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.1.row1" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.3.1.row2" /></p>

			<h2 className="MsoNormal"><a name="_Toc140555504" href="#_Toc140555504"> </a><a name="_Toc140554776" href="#_Toc140554776" className="withoutLink">3.2<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3_2" /></a></h2>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.2.row1" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.2.row2" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.3.2.row3" /></p>

			<h2 className="MsoNormal"><a name="_Toc140555505" href="#_Toc140555505"> </a><a name="_Toc140554777" href="#_Toc140554777" className="withoutLink">3.3<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3_3" /></a></h2>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.3.row1" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.3.row2" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.3.row3" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.3.row4" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.3.3.row5" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.3.row6" /></p>

			<b><span style={{ fontSize: '12.0pt', fontFamily: '"Univers","sans-serif"', textTransform: 'uppercase'}}>
</span></b>

			<h2 className="MsoNormal"><a name="_Toc140554778" href="#_Toc140554778"> </a><a name="_Toc140555506" href="#_Toc140555506" className="withoutLink">3.4<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3_4" /></a></h2>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.3.4.row1" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.3.4.row2" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.3.4.row3" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.3.4.row4" /></p>

			<p className="MsoNormal"></p>

			<h2 className="MsoNormal"><a name="_Toc140555507" href="#_Toc140555507"> </a><a name="_Toc140554779" href="#_Toc140554779" className="withoutLink">3.5<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.3_5" /></a></h2>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.3.5.row1" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.3.5.row2" /></p>

			<p className="MsoListBullet4"><span style={{ fontFamily: 'Symbol' }}>·<span
				style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.3.5.row3" /></p>

			<h1 className="MsoNormal" style={{ marginLeft: '21.6pt'}}><a name="_Toc140555508" href="#_Toc140555508"> </a><a
				name="_Toc140554780" href="#_Toc140554780" className="withoutLink">4.<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><FormattedMessage id="consignes.summary.4" /></a></h1>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.4.row1" /></p>

			<p className="MsoNormal"><FormattedMessage id="consignes.parts.4.row2" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.4.row3" /></p>

			<p className="MsoNormal"><FormattedHTMLMessage id="consignes.parts.4.row4" /></p>

			<p className="MsoNormal" style={{ marginLeft: '53.4pt', textIndent: '-18.0pt' }}><span
				style={{ fontFamily: 'Wingdings' }}>·<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.4.row5" /></p>

			<p className="MsoNormal" style={{ marginLeft: '53.4pt', textIndent: '-18.0pt' }}><span
				style={{ fontFamily: 'Wingdings' }}>·<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.4.row6" /></p>

			<p className="MsoNormal" style={{ marginLeft: '53.4pt', textIndent: '-18.0pt' }}><span
				style={{ fontFamily: 'Wingdings' }}>·<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.4.row7" /></p>

			<p className="MsoNormal" style={{ marginLeft: '53.4pt', textIndent: '-18.0pt' }}><span
				style={{ fontFamily: 'Wingdings' }}>·<span style={{ font: '7.0pt "Times New Roman"'}}>&nbsp;
</span></span><FormattedHTMLMessage id="consignes.parts.4.row8" /></p>

			<center><input type="button" value={intl.formatMessage({ id: 'consignes.closeWindow'})} onClick={hide} /></center>

		</div>
	</Draggable>
}

export default injectIntl(ConsignesCandidat)
