import * as api from './passageExamenApi'
import * as passageExamenSelectors from './passageExamenSelectors'

export const GET_INFOS_SERVEUR_LOCAL_REQUEST = 'GET_INFOS_SERVEUR_LOCAL_REQUEST'
export const GET_INFOS_SERVEUR_LOCAL_SUCCESS = 'GET_INFOS_SERVEUR_LOCAL_SUCCESS'
export const GET_INFOS_SERVEUR_LOCAL_ERROR = 'GET_INFOS_SERVEUR_LOCAL_ERROR'
export const getInfosServeurLocal = () => dispatch => {
	dispatch({ type: GET_INFOS_SERVEUR_LOCAL_REQUEST })
	return api.getInfosServeurLocal()
		.then(infos => dispatch({
			type: GET_INFOS_SERVEUR_LOCAL_SUCCESS,
			infos
		}))
		.catch(e => {
			dispatch({ type: GET_INFOS_SERVEUR_LOCAL_ERROR, e })
			throw e
		})
}

export const GET_SUJET_REQUEST = 'GET_SUJET_REQUEST'
export const GET_SUJET_SUCCESS = 'GET_SUJET_SUCCESS'
export const GET_SUJET_ERROR = 'GET_SUJET_ERROR'
export const getSujet = () => dispatch => {
	dispatch({ type: GET_SUJET_REQUEST })
	return api.getSujet()
		.then(sujet => dispatch({
			type: GET_SUJET_SUCCESS,
			sujet
		}))
		.catch(e => {
			dispatch({ type: GET_SUJET_ERROR })
			throw e
		})
}

export const SELECTIONNER_QUESTION = 'SELECTIONNER_QUESTION'
export const selectionnerQuestion = position => dispatch => dispatch({
	type: SELECTIONNER_QUESTION,
	position
})

export const QUESTION_PRECEDENTE = 'QUESTION_PRECEDENTE'
export const questionPrecedente = () => dispatch => dispatch({ type: QUESTION_PRECEDENTE })

export const QUESTION_SUIVANTE = 'QUESTION_SUIVANTE'
export const questionSuivante = () => dispatch => dispatch({ type: QUESTION_SUIVANTE })

export const MARQUER_QUESTION_REQUEST = 'MARQUER_QUESTION_REQUEST'
export const MARQUER_QUESTION_SUCCESS = 'MARQUER_QUESTION_SUCCESS'
export const MARQUER_QUESTION_ERROR = 'MARQUER_QUESTION_ERROR'
export const marquerQuestion = (positionQuestion, marquage) => dispatch => {
	dispatch({ type: MARQUER_QUESTION_REQUEST })
	return api.marquerQuestion(positionQuestion, marquage)
		.then(() => dispatch({
			type: MARQUER_QUESTION_SUCCESS,
			positionQuestion,
			marquage
		}))
		.catch(e => {
			dispatch({ type: MARQUER_QUESTION_ERROR, e })
			throw e
		})
}

export const SELECTIONNER_REPONSE_REQUEST = 'SELECTIONNER_REPONSE_REQUEST'
export const SELECTIONNER_REPONSE_SUCCESS = 'SELECTIONNER_REPONSE_SUCCESS'
export const SELECTIONNER_REPONSE_ERROR = 'SELECTIONNER_REPONSE_ERROR'
export const selectionnerReponse = (positionQuestion, reponse) => dispatch => {
	dispatch({ type: SELECTIONNER_REPONSE_REQUEST, libelleReponse: reponse })
	return api.selectionnerReponse(positionQuestion, reponse)
		.then(() => dispatch({
			type: SELECTIONNER_REPONSE_SUCCESS,
			positionQuestion,
			reponse
		}))
		.catch(e => {
			dispatch({ type: SELECTIONNER_REPONSE_ERROR, e })
			throw e
		})
}

export const MISE_A_JOUR_EPREUVE_REQUEST = 'MISE_A_JOUR_EPREUVE_REQUEST'
export const MISE_A_JOUR_EPREUVE_SUCCESS = 'MISE_A_JOUR_EPREUVE_SUCCESS'
export const MISE_A_JOUR_EPREUVE_ERROR = 'MISE_A_JOUR_EPREUVE_ERROR'
export const miseAJourEpreuve = () => (dispatch, getState) => {
	const listeQuestions = passageExamenSelectors.getQuestions(getState())
	dispatch({ type: MISE_A_JOUR_EPREUVE_REQUEST })
	return api.miseAJourEpreuve(listeQuestions)
		.then(() => dispatch({ type: MISE_A_JOUR_EPREUVE_SUCCESS }))
		.catch(e => {
			dispatch({ type: MISE_A_JOUR_EPREUVE_ERROR, e })
			throw e
		})
}
