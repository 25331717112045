import 'react-app-polyfill/stable'
import 'pepjs'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { addLocaleData } from 'react-intl'
import fr from 'react-intl/locale-data/fr'
import en from 'react-intl/locale-data/en'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducers from './config/reducers'
import { IntlProviderWrapper } from './utils/IntlContext'
import { Provider } from 'react-redux'
import './index.css'
import { saveStore } from './utils/fetchFactory'
import 'react-viewer/dist/index.css'
import { TimerProviderWrapper } from './utils/TimerContext'
import 'typeface-roboto'

// Initialisation de la partie i18n (react-intl)
addLocaleData(fr, en)

//bloquer la touche F5 et la combinaison des touches (ctrl + r)
window.onkeydown = e => {
	e = e || window.event
	// F5 is pressed
	if (e.keyCode === 116)
     {
          window.status = "F5";
          return false;
     }

	// F3 is pressed
	if (e.keyCode === 114)
	{
		window.status = "F3";
		return false;
	}

	  // Ctrl+R is pressed
	 if(e.ctrlKey && (e.which === 82)){
		window.status = "r";
		return false;
	 }
}

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory()

// Initialisation de tous les enhancers utilisés avec Redux
const enhancers = [
	applyMiddleware(
		thunk,
		routerMiddleware(history)
	)
]

// En mode dév, on utilise le devtools
if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
	enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

// Création du store de redux
let store = createStore(
	combineReducers({
		...reducers,
		router: connectRouter(history)
	}), {},
	compose(...enhancers)
)

saveStore(store)

ReactDOM.render(
	<Provider store={store}>
		<IntlProviderWrapper>
			<TimerProviderWrapper>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</TimerProviderWrapper>
		</IntlProviderWrapper>
	</Provider>,
	document.getElementById('root')
)
