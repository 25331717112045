import { flatten } from 'flat'

export default flatten({
	loginForm: {
		titre: 'Identification',
		identifiant: 'Login',
		motDePasse: 'Password',
		accederEpreuve: 'Start test',
		annuler: 'Cancel (fortement recommandé)',
		ignorer: 'Ignorer et accéder à l\'épreuve',
		consulterConsignes: 'View instructions'
	},
	marquage: {
		retirer: 'Remove marking',
		marquer: 'Mark the question'
	},
	filAriane: {
		examen: 'Exam {nomExamen}',
		epreuve: 'Subject {libelleCourt} / {libelleLong} ({total} {total, plural, one {mark} other {marks}})'
	},
	timer: {
		tempsRestant: 'Time remaining:',
		terminer: 'End test'
	},
	nbPoints: '+{points} {points, plural, one {mark} other {marks}}',
	legend: {
		nonrepondue: 'Unanswered',
		marquee: 'Marked',
		repondue: 'Answered'
	},
	errors: {
		login: {
			invalide: 'Your login and/or password is incorrect. Please retype the login and password that have been given to you when you enter the examinations room. Refer to the rooms supervisor in case of a problem',
			reconnexion: 'You are not allowed to reconnect. Please refer to the supervisor',
			reponseCentral: 'L\'épreuve est terminée et le fichier réponses est enregistré sur le serveur central.',
			reponseSecours: 'L\'épreuve est terminée et le fichier réponses est enregistré sur le serveur de secours.'
		},
		exception: {
			hibernate: 'Échec : problème survenu lors de la sauvegarde de la réponse.',
			baseDonnees: 'Échec : la base de données est hors service, merci de prévenir le superviseur.',
			generationSujet: 'The current application state does not allow to generate the examination\'s subject.'
		},
		pex: {
			connexionImpossible: 'Unable to connect',
			login: {
				invalide: 'Your login and/or password is incorrect. Please retype the login and password that have been given to you when you enter the examinations room. Refer to the rooms supervisor in case of a problem'
			}
		},
		temps: {
			depasse: 'You took too much time to finish your test'
		}
	},
	terminerEpreuve: {
		confirm: 'Do you confirm the end of the test?',
		oui: 'Yes',
		non: 'No',
		message: 'Your test is now finished, please reach the exit and report to the supervisor',
		quitter: 'Quit',
		tempsImpartiEcoule: {
			1: 'End of subject duration',
			2: 'Please click on the "End test" button"'
		}
	},
	consignes: {
		title: 'Information about on screen exam taking',
		closeWindow: 'Close the window',
		summary: {
			1: 'exam room entrance',
			2: 'authorised materials and documents',
			3: 'during exam',
			'3_1': 'Connection',
			'3_2': 'Duration and end of the exam',
			'3_3': 'Questions',
			'3_4': 'Navigation in the exam',
			'3_5': 'Functions',
			4: 'Exam room exit'
		},
		parts: {
			1: {
				row1: 'Please be present <b>30 minutes</b> before the expected start time of your exam.',
				row2: 'After identity checks, verify:',
				row3: 'Your access sheet is correct',
				row4: 'You have received the Appendices booklet for your exam (if required)',
				row5: 'Go and sit in your position in silence in order not to bother other applicants. ',
				row6: '<b><i>Note</i></b><i>: an access sheet is provided for each exam you are to take.</i>'
			},
			2: {
				row1: 'In the exam room, you are only allowed:',
				row2: 'Your Access sheet',
				row3: 'The Appendices booklet (if required) ',
				row4: 'Rough paper provided by invigilators',
				row5: 'A navigational computer <b><u>without</u></b> its user’s manual ',
				row6: 'A set of dividers',
				row7: 'A navigation ruler ',
				row8: 'A Pencil and a rubber.',
				row9: 'THE USE OF ANY OTHER DOCUMENT OR MATERIAL IS STRICTLY PROHIBITED AND WILL SYSTEMATICALLY LEAD TO THE FILING OF A REPORT AND PROCEEDINGS FOR ATTEMPTED FRAUD',
				row10: 'Put your belongings in the dedicated locations.'
			},
			3: {
				row1: 'Please be very quiet in the exam room for other applicants.',
				1: {
					row1: 'To begin, please<b>enter</b> your <b>identification number</b> and your <b>password</b> that are indicated in your access sheet.',
					row2: 'After login, the system automatically creates your exam and the countdown begins.'
				},
				2: {
					row1: '<b>Time remaining</b> for taking your exam is indicated in the top right part of the screen.',
					row2: 'You can quit at any time clicking on the “<b>quit </b>“ button ',
					row3: 'If allotted time is over, you will not be able to continue and you will have to quit.'
				},
				3: {
					row1: '<b>questions</b> of this subject are <b>independent</b> The score of each question is indicated beside the number of the question. There are no negative marks.',
					row2: ' ',
					row3: 'To enter or modify your answer to a question, click on the proposal of your choice. The selected answer gets highlighted.',
					row4: 'If a <b>question requires the use </b>of one or several <b>appendices</b>, click on the <b>corresponding link</b> to open the document in a new window.',
					row5: 'Close the window, after consulting in order not to have a confusing screen.',
					row6: 'Some appendices in example diagrams need paper format. You will find them in the <b>appendices booklet</b> provided before entering the exam room.'
				},
				4: {
					row1: 'To move in your exam, use: ',
					row2: 'The previous (<b>&lt;&lt;</b>) and next (<b>&gt;&gt;</b>) arrows to get to the previous or the next question. ',
					row3: 'The <b>Questions table </b>: to directly go to a question<i> (click on the question number)</i>.',
					row4: 'The<b> Questions table</b> gives information about the work you have done <i>(questions answered or not)</i> and shows the marked questions.'
				},
				5: {
					row1: '<b>Calculator</b>: If allowed, a calculator is available. Click on the calculator pictogram to make it appear.',
					row2: '<b>Question&nbsp;marking: </b>to help you navigate back easily to a question, you can mark it. (Click on question marking). You can delete the marking by the same way.',
					row3: '<b>Informations</b>: at any time during your exam you can consult information about on screen exam taking.'
				}
			},
			4: {
				row1: 'Please <b>tidy</b> your position for the next applicant.',
				row2: 'Gather all the provided documents.',
				row3: 'Leave the room silently.',
				row4: 'To obtain your results, please hand back:',
				row5: 'Your <b>access sheet</b>',
				row6: '<b>Rough paper </b>',
				row7: '<b>Appendices booklet</b> if provided ',
				row8: '<b>all other documents or materials </b>you have received.'
			}
		}
	}
})
