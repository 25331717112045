import React from 'react'
import FilAriane from './components/FilAriane'
import ListeQuestions from './components/ListeQuestions'
import Question from './components/Question'
import { getUser } from '../login/services/loginActions'
import { getInfosServeurLocal, getSujet } from './services/passageExamenActions'
import { connect } from 'react-redux'
import Loader from 'react-loader-advanced'
import * as passageExamenSelectors from './services/passageExamenSelectors'
import { TimerContext } from '../../utils/TimerContext'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import TempsEcoule from './components/TempsEcoule'

const PassageExamen = ({ showLoader }) => {

	const [displayLoader, setDisplayLoader] = React.useState(false)
	const [displayQuestion, setDisplayQuestion] = React.useState(true)

	const displayLoaderTimeout = React.useRef(-1)
	React.useEffect(() => {
		if (showLoader) {
			displayLoaderTimeout.current = setTimeout(() => setDisplayLoader(true), 1000)
		} else {
			clearTimeout(displayLoaderTimeout.current)
			setDisplayLoader(false)
		}
	}, [showLoader])

	return <TimerContext.Consumer>
		{({ timerMinutes }) =>
			<Loader
				show={showLoader}
				backgroundStyle={{ backgroundColor: '#FFFFFF', opacity: 0 }}
				message={<>{displayLoader && <span>Envoi en cours...</span>}</>}
			>
				<FilAriane changeDisplay={setDisplayQuestion}/>
				{timerMinutes > 0 ?
					<>
						<ListeQuestions />
						
					</> : <> </> 
				}
				{timerMinutes > 0 ?
				displayQuestion ? 
					<>
						<Question />
					</> : <> </> :
					<TempsEcoule />
				}
			</Loader>
		}
	</TimerContext.Consumer>
}

const mapStateToProps = state => ({
	showLoader: passageExamenSelectors.showLoader(state)
})

const actions = {
	getUser,
	getInfosServeurLocal,
	getSujet
}

export default compose(injectIntl, connect(mapStateToProps, actions))(PassageExamen)
