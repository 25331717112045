import classNames from 'classnames'
import React from 'react'
import Draggable from 'react-draggable'

const Calculette = ({ close }) => {

	const champRef = React.useRef(null)
	const [memoire, setMemoire] = React.useState('0')
	const [buffer, setBuffer] = React.useState(null)
	const [radians, setRadians] = React.useState(false)

	React.useEffect(() => {
		if (champRef.current) {
			champRef.current.focus()
		}
	}, [champRef])

	const nettoyer = () => {
		champRef.current.value = ''
	}

	const insert = (valeur, retrait, selection) => {
		const champ = champRef.current
		const startPos = champ.selectionStart
		const endPos = champ.selectionEnd
		champ.focus()
		champ.value = champ.value.substring(0, startPos)
			+ valeur
			+ champ.value.substring(endPos, champ.value.length)
		champ.setSelectionRange(startPos + valeur.length - retrait, startPos + valeur.length - retrait + selection)
	}

	const touche = valeur => {
		insert(valeur, 0, 0)
	}

	const fonction = valeur => {
		insert(valeur, 1, 0)
	}

	const fonctionpara = (valeur, retrait) => {
		insert(valeur, retrait, 1)
	}

	const ms = () => {
		setMemoire(champRef.current.value)
	}

	const mr = () => {
		/* eslint-disable no-eval */
		let mem = eval(traduireExpressions(memoire)) + ''
		/* eslint-disable no-eval */

		if (isNaN(mem)) {
			mem = 'Erreur'
		}
		if (typeof mem === 'undefined') {
			mem = 'Non défini'
		}
		touche(mem)
	}

	const mc = () => {
		setMemoire('0')
	}

	const mp = () => {
		setMemoire(memoire => `${memoire}+${champRef.current.value}`)
	}

	const back = () => {
		const champ = champRef.current
		champ.value = buffer
		champ.focus()
	}

	const egal = () => {
		const champ = champRef.current
		setBuffer(champ.value)
		try {
			/* eslint-disable no-eval */
			champ.value = eval(traduireExpressions(champ.value))
			/* eslint-disable no-eval */
			if (isNaN(champ.value)) {
				champ.value = 'Erreur dans l\'expression'
			} else if (typeof champ.value === 'undefined') {
				champ.value = ''
			}
		} catch (err) {
			champ.value = 'Erreur dans l\'expression'
		}
		champ.focus()
	}

	/* eslint-disable no-unused-vars */
	const fact = n => {
		if (isNaN(n) || n < 0) {
			return 'Erreur'
		}
		if (n === 0) {
			return 1
		}
		if (n > 0) {
			return n * fact(n - 1)
		}
	}
	/* eslint-disable no-unused-vars */

	const presstouche = event => {
		if (event.key === 'Enter') {
			egal()
		} else if (event.key === 'Backspace') {
			nettoyer()
		}
	}

	const traduireExpressions = (operation) => {
		operation = operation.replace(/rac/g, 'Math.sqrt')
		operation = operation.replace(/Exp\(/g, 'Math.pow(10,')
		operation = operation.replace(/log/g, '(1/2.302585092994045684018)*Math.log')
		operation = operation.replace(/ln/g, 'Math.log')
		if (radians) {
			operation = operation.replace(/sin/g, 'Math.sin')
			operation = operation.replace(/cos/g, 'Math.cos')
			operation = operation.replace(/tan/g, 'Math.tan')
			operation = operation.replace(/aMath.sin/g, 'Math.asin')
			operation = operation.replace(/aMath.cos/g, 'Math.acos')
			operation = operation.replace(/aMath.tan/g, 'Math.atan')
		} else {
			// Reperage des parentheses correspondant a des fonctions trigo
			const listeParentheses = []
			let indiceParenthese = 0
			// Construction de la liste des indices de parentheses ouvrantes de fonctions trigonométriques
			for (let i = 0; i < operation.length; i++) {
				if (operation.charAt(i) === '(') {
					indiceParenthese = indiceParenthese + 1
					const sousChaine = operation.substr(i - 3, 3)
					if (i > 2 && (sousChaine === 'sin' || sousChaine === 'cos' || sousChaine === 'tan')) {
						listeParentheses.push(indiceParenthese)
					}
				}
			}
			// Remplacement des parentheses fermantes des fonctions trigo par une double parenthese
			indiceParenthese = 0
			if (listeParentheses.length !== 0) {
				let nouvelleChaine = ''
				for (let i = operation.length - 1; i >= 0; i--) {
					if (operation.charAt(i) === ')') {
						indiceParenthese = indiceParenthese + 1
						if (listeParentheses.length > 0 && listeParentheses.slice(0, 1)[0] === indiceParenthese) {
							listeParentheses.shift()
							nouvelleChaine = '))' + nouvelleChaine
						} else {
							nouvelleChaine = operation.charAt(i) + nouvelleChaine
						}
					} else {
						nouvelleChaine = operation.charAt(i) + nouvelleChaine
					}
				}
				operation = nouvelleChaine
			}
			operation = operation.replace(/sin/g, 'Math.sin((PI/180)*')
			operation = operation.replace(/cos/g, 'Math.cos((PI/180)*')
			operation = operation.replace(/tan/g, 'Math.tan((PI/180)*')
			operation = operation.replace(/aMath.sin\(\(PI\/180\)\*/g, '((180/PI)*Math.asin')
			operation = operation.replace(/aMath.cos\(\(PI\/180\)\*/g, '((180/PI)*Math.acos')
			operation = operation.replace(/aMath.tan\(\(PI\/180\)\*/g, '((180/PI)*Math.atan')
		}
		operation = operation.replace(/PI/g, 'Math.PI')
		operation = operation.replace(/puiss/g, 'Math.pow')
		// Securite (pour empecher le scripting dans le champ d'operation)
		const testSecurite = operation.toLowerCase()
		if (testSecurite.indexOf('window', 0) >= 0) {
			operation = 'Erreur'
		}
		return operation
	}

	return (
		<Draggable cancel="input" onStop={() => champRef.current.focus()}>
			<div id="calculatrice">
				<div className="header">
					<input type="text" ref={champRef} maxLength="256" size="55" onKeyUp={presstouche} />
					<span onClick={close} className="far fa-window-close c-primary-1" />
				</div>
				<div className="boutons">
					<div id="fonctions" className="touches">
						<div onClick={() => touche('(')}>(</div>
						<div onClick={() => touche(')')}>)</div>
						<div onClick={() => fonction('Exp()')}>Exp</div>
						<div onClick={() => fonction('fact()')}>n!</div>
						<div onClick={() => fonction('log()')}>log</div>
						<div onClick={() => fonction('sin()')}>sin</div>
						<div onClick={() => fonction('asin()')}>asin</div>
						<div onClick={() => fonctionpara('puiss(x,2)', 4)}>x^2</div>
						<div onClick={() => fonctionpara('rac(x)', 2)}>&radic;<span style={{ textDecoration: 'overline' }}>x</span></div>
						<div onClick={() => fonction('ln()')}>ln</div>
						<div onClick={() => fonction('cos()')}>cos</div>
						<div onClick={() => fonction('acos()')}>acos</div>
						<div onClick={() => fonctionpara('puiss(x,y)', 4)}>x^y</div>
						<div onClick={() => touche('1/')}>1/x</div>
						<div onClick={() => touche('PI')}>pi</div>
						<div onClick={() => fonction('tan()')}>tan</div>
						<div onClick={() => fonction('atan()')}>atan</div>
						<div id="modeCalcul" className="touches">
							<input id="degres" type="radio" name="mode" defaultChecked onChange={() => setRadians(false)} /><label
							htmlFor="degres">Deg</label>
							<input id="radians" type="radio" name="mode" onChange={() => setRadians(true)} /><label htmlFor="radians">Rad</label>
						</div>
					</div>
					<div id="memoires" className="touches">
						<div onClick={ms} title="Mise en m&eacute;moire">MS</div>
						<div className={classNames({ used: memoire !== '0' })} id="mem" onClick={mr}
						     title="Affiche le contenu de la m&eacute;moire">MR
						</div>
						<div onClick={mc} title="Remet la m&eacute;moire &agrave; z&eacute;ro">MC</div>
						<div onClick={mp} title="Ajoute l'expression courante &agrave; la m&eacute;moire">M+</div>
					</div>

					<div id="principal" className="touches">
						<div className="critique" onClick={nettoyer}>C</div>
						<div className="critique" onClick={back} title="Revenir &agrave; la derni&eacute;re op&eacute;ration">R</div>
						<div id="egal" onClick={egal}>=</div>
					</div>
					<div id="pave" className="touches">
						<div onClick={() => touche('7')}>7</div>
						<div onClick={() => touche('8')}>8</div>
						<div onClick={() => touche('9')}>9</div>
						<div onClick={() => touche('/')}>/</div>
						<div onClick={() => touche('4')}>4</div>
						<div onClick={() => touche('5')}>5</div>
						<div onClick={() => touche('6')}>6</div>
						<div onClick={() => touche('*')}>x</div>
						<div onClick={() => touche('1')}>1</div>
						<div onClick={() => touche('2')}>2</div>
						<div onClick={() => touche('3')}>3</div>
						<div onClick={() => touche('-')}>-</div>
						<div onClick={() => touche('0')}>0</div>
						<div onClick={() => touche('.')}>.</div>
						<div onClick={() => touche('/100')}>%</div>
						<div onClick={() => touche('+')}>+</div>
					</div>
				</div>

			</div>
		</Draggable>
	)
}

export default Calculette
