import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as passageExamenSelectors from '../services/passageExamenSelectors'
import { marquerQuestion, questionPrecedente, questionSuivante } from '../services/passageExamenActions'
import BlocReponse from './BlocReponse'
import Annexes from './Annexe'
import Loading from 'react-viewer/lib/Loading'

import { renderTexte } from '../../../utils/questionUtils'

const Question = ({ question, intl, isPremiereQuestion, isDerniereQuestion, questionPrecedente, questionSuivante, marquerQuestion, urlAnnexes, showLoader }) => {

	const [open, setOpen] = React.useState(false)
	const [selectedAnnexe, setSelectedAnnexe] = React.useState(null)
	const [listeAnnexes, setListeAnnexes] = React.useState([])
	const openPopup = (annexe) => {
		setOpen(true)
		setSelectedAnnexe(annexe)
	}

	React.useEffect(() => {
		setOpen(false)
		setSelectedAnnexe(null)
		setListeAnnexes(question.listeAnnexes)
	}, [question])

	React.useEffect(() => () => {
		setOpen(false)
		setSelectedAnnexe(null)
		setListeAnnexes([])
	}, [])


	if (question) {
		return <main>
			<div className="main-header mt-3">
				<div className="container-fluid">
					<div className="container">
						<div className="header-group">
							<div className="header-left col-3">
								<div className="titre">
									<span className="title">Question {`0${question.position}`.slice(-2)}</span>
									<FormattedMessage id="nbPoints" values={{ points: question.score }} className="subtitle" />
								</div>
								{Boolean(question.listeAnnexes.length) && <div className="annexes">
									Annexes
									{listeAnnexes.map((annexe, index) => <div className="item" key={index} onClick={() => openPopup(annexe)}>{annexe.numero}</div>)}
								</div>}
							</div>
							<div className="header-right col">
								<div className="question">
									{renderTexte(question.question)}
								</div>
							</div>
							<div className="favoris" onClick={() => marquerQuestion(question.position, !question.marquage)}>
								{showLoader ? <Loading /> : (question.marquage ? <span className="ml-1 fa fa-times" /> : <span className="fa fa-check" />)}
								<span>{question.marquage ? intl.formatMessage({ id: 'marquage.retirer' }) : intl.formatMessage({ id: 'marquage.marquer' })}</span>
							</div>
							<div className="swiper-controls">
								<button onClick={() => !isPremiereQuestion && questionPrecedente()}><span
									className="fa fa-chevron-left" /></button>
								<button onClick={() => !isDerniereQuestion && questionSuivante()}><span
									className="fa fa-chevron-right" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main-body">
				<div className="container-fluid">
					<div className="container">
						<div className="group-answers">
							<BlocReponse position={question.position} libelleReponse="A" />
							<BlocReponse position={question.position} libelleReponse="B" />
							<BlocReponse position={question.position} libelleReponse="C" />
							<BlocReponse position={question.position} libelleReponse="D" />
						</div>
					</div>
				</div>
			</div>
			{selectedAnnexe && (<Annexes
				annexes={[{
					src: `${urlAnnexes}${selectedAnnexe.idMatiere}/${selectedAnnexe.nomImageEtExtension}`,
					alt: selectedAnnexe.numero
				}]}
				index={0}
				open={open}
				close={() => {
					setOpen(false)
					setSelectedAnnexe(null)
				}}
			/>)}
		</main>
	}
	return null
}


const mapStateToProps = (state) => ({
	question: passageExamenSelectors.getContenuQuestion(state),
	isPremiereQuestion: passageExamenSelectors.isPremiereQuestion(state),
	isDerniereQuestion: passageExamenSelectors.isDerniereQuestion(state),
	urlAnnexes: passageExamenSelectors.getUrlAnnexes(state),
	showLoader: passageExamenSelectors.showLoaderMarquage(state)
})

const actions = {
	questionPrecedente,
	questionSuivante,
	marquerQuestion
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions)
)(Question)
