import { fetchFactory } from '../../../utils/fetchFactory'
import 'whatwg-fetch'

const fetchNoTimeout = path => {
	const config = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=utf-8',
			'Authorization-Oceane': localStorage.getItem('token')
		}
	}
	return fetch(`${process.env.REACT_APP_SERVER_URL}/api/pex${path}`, config)
		.then(response => {
			if (response.ok) {
				return response.json()
			} else {
				return response.json().then(result => Promise.reject(result))
			}
		})
}

export const getInfosServeurLocal = () => fetchNoTimeout('/infos')

export const getSujet = () => fetchNoTimeout('/sujet')

export const marquerQuestion = (positionQuestion, marquage) => fetchFactory('/marquage', {
	method: 'post',
	body: JSON.stringify({
		positionQuestion,
		marquage
	})
})

export const selectionnerReponse = (positionQuestion, reponse) => fetchFactory('/reponse', {
	method: 'post',
	body: JSON.stringify({
		positionQuestion,
		reponse
	})
})

export const miseAJourEpreuve = listeQuestions => fetchFactory('/mise-a-jour-epreuve', {
	method: 'post',
	body: JSON.stringify(listeQuestions)
}, true)
